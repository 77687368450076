<template>
  <div class="role-list">
    <div class="page-header">
      <h2>角色列表</h2>
      <div class="header-actions">
        <el-button type="primary" @click="handleAdd">添加角色</el-button>
      </div>
    </div>

    <el-card class="box-card">
      <div class="filter-container">
        <el-input
          v-model="listQuery.search"
          placeholder="请输入角色名称"
          style="width: 200px;"
          class="filter-item"
          @keyup.enter.native="handleFilter"
        />
        <el-button class="filter-item" type="primary" icon="el-icon-search" @click="handleFilter">
          搜索
        </el-button>
      </div>

      <el-table
        v-loading="listLoading"
        :data="roleList"
        element-loading-text="加载中..."
        border
        fit
        highlight-current-row
        style="width: 100%;"
      >
        <el-table-column prop="id" label="ID" align="center" width="80" />
        <el-table-column prop="role_name" label="角色名称" align="center" min-width="120" />
        <el-table-column prop="description" label="角色描述" align="center" min-width="200" />
        <el-table-column prop="created_at" label="创建时间" align="center" width="180" />
        <el-table-column prop="updated_at" label="更新时间" align="center" width="180" />
        <el-table-column label="操作" align="center" width="250" class-name="small-padding fixed-width">
          <template slot-scope="{row}">
            <el-button type="primary" size="mini" @click="handleSetMenu(row)">
              设置权限
            </el-button>
            <el-button type="success" size="mini" @click="handleEdit(row)">
              编辑
            </el-button>
            <el-button type="danger" size="mini" @click="handleDelete(row)">
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>

      <pagination
        v-show="total > 0"
        :total="total"
        :page.sync="listQuery.page"
        :limit.sync="listQuery.page_size"
        @pagination="getRoleList"
      />
    </el-card>

    <!-- 设置菜单权限对话框 -->
    <el-dialog title="设置菜单权限" :visible.sync="menuDialogVisible" width="600px">
      <el-tree
        ref="menuTree"
        :data="menuList"
        :props="menuProps"
        show-checkbox
        node-key="id"
        default-expand-all
      />
      <div slot="footer" class="dialog-footer">
        <el-button @click="menuDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="saveRoleMenus">确 定</el-button>
      </div>
    </el-dialog>

    <!-- 添加/编辑角色对话框 -->
    <el-dialog :title="dialogTitle" :visible.sync="roleDialogVisible" width="500px">
      <el-form ref="roleForm" :model="roleForm" :rules="rules" label-width="100px">
        <el-form-item label="角色名称" prop="role_name">
          <el-input v-model="roleForm.role_name" placeholder="请输入角色名称" />
        </el-form-item>
        <el-form-item label="角色描述" prop="description">
          <el-input
            v-model="roleForm.description"
            type="textarea"
            :rows="4"
            placeholder="请输入角色描述"
          />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="roleDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitRoleForm" :loading="submitLoading">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getRoles, deleteRole, getAllMenus, setRoleMenus, getRoleDetail, createRole, updateRole } from '@/api'
import Pagination from '@/components/Pagination'

export default {
  name: 'RoleList',
  components: { Pagination },
  data() {
    return {
      roleList: [],
      total: 0,
      listLoading: true,
      listQuery: {
        page: 1,
        page_size: 10,
        search: ''
      },
      menuDialogVisible: false,
      menuList: [],
      menuProps: {
        children: 'children',
        label: 'name'
      },
      currentRole: null,
      
      // 角色表单相关数据
      roleDialogVisible: false,
      dialogTitle: '添加角色',
      roleForm: {
        role_name: '',
        description: ''
      },
      rules: {
        role_name: [
          { required: true, message: '请输入角色名称', trigger: 'blur' },
          { min: 2, max: 20, message: '长度在 2 到 20 个字符', trigger: 'blur' }
        ],
        description: [
          { max: 200, message: '长度不能超过 200 个字符', trigger: 'blur' }
        ]
      },
      submitLoading: false,
      isEdit: false
    }
  },
  created() {
    this.getRoleList()
  },
  methods: {
    async getRoleList() {
      this.listLoading = true
      try {
        const res = await getRoles(this.listQuery)
        if (res.code === 200) {
          this.roleList = res.data.roles
          this.total = res.data.total
        }
      } catch (error) {
        console.error('获取角色列表失败:', error)
        this.$message.error('获取角色列表失败')
      } finally {
        this.listLoading = false
      }
    },
    handleFilter() {
      this.listQuery.page = 1
      this.getRoleList()
    },
    handleAdd() {
      this.isEdit = false
      this.dialogTitle = '添加角色'
      this.roleForm = {
        role_name: '',
        description: ''
      }
      this.roleDialogVisible = true
      // 在下一个事件循环中重置表单，确保表单已经渲染
      this.$nextTick(() => {
        if (this.$refs.roleForm) {
          this.$refs.roleForm.clearValidate()
        }
      })
    },
    async handleEdit(row) {
      this.isEdit = true
      this.dialogTitle = '编辑角色'
      this.currentRole = row
      this.roleDialogVisible = true
      
      try {
        const res = await getRoleDetail(row.id)
        if (res.code === 200) {
          const { role_name, description } = res.data
          this.roleForm = { role_name, description }
        }
      } catch (error) {
        console.error('获取角色详情失败:', error)
        this.$message.error('获取角色详情失败')
      }
      
      // 在下一个事件循环中重置表单，确保表单已经渲染
      this.$nextTick(() => {
        if (this.$refs.roleForm) {
          this.$refs.roleForm.clearValidate()
        }
      })
    },
    async handleDelete(row) {
      try {
        await this.$confirm('确认删除该角色吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
        
        const res = await deleteRole(row.id)
        if (res.code === 200) {
          this.$message.success('删除成功')
          this.getRoleList()
        }
      } catch (error) {
        if (error !== 'cancel') {
          console.error('删除角色失败:', error)
          this.$message.error('删除角色失败')
        }
      }
    },
    async handleSetMenu(row) {
      this.currentRole = row
      this.menuDialogVisible = true
      
      try {
        // 获取所有菜单
        const menuRes = await getAllMenus()
        if (menuRes.code === 200) {
          this.menuList = menuRes.data
          
          // 获取角色详情，设置已选菜单
          const roleRes = await getRoleDetail(row.id)
          if (roleRes.code === 200) {
            this.$nextTick(() => {
              this.$refs.menuTree.setCheckedKeys(roleRes.data.menu_ids || [])
            })
          }
        }
      } catch (error) {
        console.error('获取菜单数据失败:', error)
        this.$message.error('获取菜单数据失败')
      }
    },
    async saveRoleMenus() {
      if (!this.currentRole) return
      
      try {
        const menuIds = this.$refs.menuTree.getCheckedKeys()
        const res = await setRoleMenus(this.currentRole.id, { menu_ids: menuIds })
        
        if (res.code === 200) {
          this.$message.success('设置菜单权限成功')
          this.menuDialogVisible = false
        }
      } catch (error) {
        console.error('设置菜单权限失败:', error)
        this.$message.error('设置菜单权限失败')
      }
    },
    submitRoleForm() {
      this.$refs.roleForm.validate(async valid => {
        if (valid) {
          this.submitLoading = true
          try {
            let res
            if (this.isEdit) {
              // 编辑角色
              res = await updateRole(this.currentRole.id, this.roleForm)
            } else {
              // 添加角色
              res = await createRole(this.roleForm)
            }
            
            if (res.code === 200) {
              this.$message.success(this.isEdit ? '角色更新成功' : '角色创建成功')
              this.roleDialogVisible = false
              this.getRoleList()
            }
          } catch (error) {
            console.error(this.isEdit ? '更新角色失败:' : '创建角色失败:', error)
            this.$message.error(this.isEdit ? '更新角色失败' : '创建角色失败')
          } finally {
            this.submitLoading = false
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>

<style scoped>
.role-list {
  padding: 20px;
}

.page-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.header-actions {
  display: flex;
  gap: 10px;
}

.filter-container {
  margin-bottom: 20px;
}

.filter-item {
  margin-right: 10px;
}
</style> 